<script>
import constants from '@shared/config/constants';
import AIContentHistory from '@app/components/AIContentHistory.vue';
import AIContentGeneratorTitleIdeasForm from '@app/components/AIContentGeneratorTitleIdeasForm.vue';
import AIContentGeneratorValuePropositionForm from '@app/components/AIContentGeneratorValuePropositionForm.vue';
import AIContentGeneratorOutlineForm from '@app/components/AIContentGeneratorOutlineForm.vue';
import AIContentGeneratorSalesPageForm from '@app/components/AIContentGeneratorSalesPageForm.vue';

export default {
  components: {
    AIContentHistory,
    title_ideas: AIContentGeneratorTitleIdeasForm,
    value_proposition: AIContentGeneratorValuePropositionForm,
    outline: AIContentGeneratorOutlineForm,
    sales_page: AIContentGeneratorSalesPageForm,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return constants.AI_CONTENT_TYPES.includes(value);
      },
    },
  },
  data: () => ({
    currentTab: 'generator',
  }),
  provide() {
    return { viewerHasInsertCta: false };
  },
  methods: {
    onContentChange(content) {
      this.$emit('content', content);
    },
  },
};
</script>

<template>
  <AppCardModal hasCloseBtn @close="$emit('close')">
    <template #title>
      Je suis votre assistant Copywriter !
    </template>
    <template #body>
      <div class="tabs mb-10">
        <ul>
          <li
            :class="{'is-active': currentTab === 'generator'}"
            @click.prevent="currentTab = 'generator'"
          >
            <a href="#">Copywriter IA</a>
          </li>
          <li
            :class="{'is-active': currentTab === 'history'}"
            @click.prevent="currentTab = 'history'"
          >
            <a href="#">Historique</a>
          </li>
        </ul>
      </div>
      <div>
        <component
          v-if="currentTab === 'generator'"
          :is="type"
          @content="onContentChange"
        />
        <AIContentHistory
          v-if="currentTab === 'history'"
          :type="type"
          @content="onContentChange"
        />
      </div>
    </template>
    <template #foot>
      {{ ' ' }}
    </template>
  </AppCardModal>
</template>
