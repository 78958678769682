<script>
import TiptapEditor from '@shared/components/TiptapEditor.vue';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';
import AIContentTrainingBox from '@app/components/AIContentTrainingBox.vue';

export default {
  components: { TiptapEditor, VueSimpleEmbed },
  head: {
    title: 'Description',
  },
  data() {
    return {
      isLoading: false,
      videoPreviewUrl: '',
      training: {
        summary: '',
        promotional_video_url: '',
        description: '',
        goals: '',
        target: '',
        requirements: '',
      },
    };
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    pageURL() {
      let p = '';

      if (this.currentTraining.status === 'DRAFT') {
        p = `?p=${this.$store.state.auth.previewToken}`;
      }

      return `${this.$store.getters['auth/url']}/formations/${this.currentTraining.slug}${p}`;
    },
  },
  created() {
    Object.keys(this.training)
      .forEach((k) => (this.training[k] = this.currentTraining[k]));

    this.videoPreviewUrl = this.training.promotional_video_url;
  },
  methods: {
    showAIContentGenerator(type) {
      const modal = this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: AIContentTrainingBox,
        width: 640,
        hasModalCard: true,
        props: {
          type,
        },
        events: {
          content: () => {
            // if (content.type === 'value_proposition') {
            //   this.training.summary = content.generated_content;
            // } else if (content.type === 'sales_page') {
            //   this.$refs.description.setValue(content.generated_content);
            // }

            modal.close();
          },
        },
      });
    },
    handle() {
      const training = { ...this.training };
      this.isLoading = true;
      this.$store.dispatch('trainings/update', {
        uuid: this.currentTraining.uuid,
        training,
        isCurrent: true,
      })
        .then(() => {
          this.videoPreviewUrl = this.training.promotional_video_url;
          this.$showMessage.success();
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Vidéo de présentation
      </h2>
      <p>
        Ajoutez une vidéo de présentation de votre formation, à travers
        un lien <strong>YouTube</strong>, <strong>Vimeo</strong>,
        ou <strong>Dailymotion</strong>.
      </p>
      <p>Une vidéo de présentation rendra votre page de formation plus dynamique !</p>
      <p class="has-text-weight-bold">
        Votre page de formation est accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <b-input
            type="url"
            pattern="https?://.+"
            placeholder="Lien vers la vidéo"
            v-model="training.promotional_video_url"
          />
        </b-field>

        <div v-if="videoPreviewUrl" class="field">
          <VueSimpleEmbed
            class="has-text-centered"
            :attrs="{
              class: 'mw-full mx-auto',
              width: '560',
              height: '315'
            }"
            :url="videoPreviewUrl"
          >
            <b-notification type="is-danger" :closable="false">
              Il y a eu un problème de chargement...
            </b-notification>
          </VueSimpleEmbed>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Description
      </h2>
      <p>
        Décrivez votre formation pour donner envie aux apprenants de s'y inscrire.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page de formation
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <template #label>
            <span class="flex-between-center" @click.prevent>
              Description courte (apparaît dans les listes)

              <b-button
                type="is-danger is-light"
                class="ml-3"
                icon-left="magic"
                @click="showAIContentGenerator('value_proposition')"
              >
                Générer
              </b-button>
            </span>
          </template>
          <b-input
            type="textarea"
            placeholder="Décrivez la formation en une ou deux phrases maximum"
            maxlength="200"
            v-model="training.summary"
          />
        </b-field>

        <b-field class="mt-5">
          <template #label>
            <span class="flex-between-center" @click.prevent>
              Description longue (apparaît dans la fiche)

              <b-button
                type="is-danger is-light"
                class="ml-3"
                icon-left="magic"
                @click="showAIContentGenerator('sales_page')"
              >
                Générer
              </b-button>
            </span>
          </template>

          <TiptapEditor
            ref="description"
            :value="training.description"
            :hidden="['h2']"
            @input="training.description = $event"
          />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Objectifs
      </h2>
      <p>
        Décrivez les objectifs pédagogiques de votre formation.
        Soyez le plus factuel et précis possible, cet élément est décisif pour les apprenants.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page de formation
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champs est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <TiptapEditor
            :value="training.goals"
            :hidden="['h2']"
            @input="training.goals = $event"
          />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Public cible
      </h2>
      <p>
        Décrivez les publics cibles de votre formation.
        N'hésitez pas à le faire sous forme de liste, par exemple.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page de formation
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <TiptapEditor
            :value="training.target"
            :hidden="['h2']"
            @input="training.target = $event"
          />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Prérequis
      </h2>
      <p>
        Décrivez les prérequis nécessaires pour suivre votre formation.
        Là aussi, n'hésitez pas à le faire sous forme de liste.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page de formation
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <TiptapEditor
            :value="training.requirements"
            :hidden="['h2']"
            @input="training.requirements = $event"
          />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
