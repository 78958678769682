<script>
import { mapGetters, mapState } from 'vuex';
import constants from '@shared/config/constants';
import AIContentViewer from '@app/components/AIContentViewer.vue';

export default {
  props: {
    type: {
      type: String,
      default: null,
      validator(value) {
        return constants.AI_CONTENT_TYPES.includes(value);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      localType: this.type,
    };
  },
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
    ...mapState('aiContents', [
      'contents',
    ]),
  },
  methods: {
    showContent(content) {
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: AIContentViewer,
        width: 640,
        hasModalCard: true,
        props: {
          content,
        },
        events: {
          success: (content) => this.$emit('content', content),
        },
      });
    },
    getContents(type = null, page = 1) {
      const params = { page };

      this.page = page;

      if (type) {
        params.type = type;
      }

      this.isLoading = true;
      this.$store.dispatch('aiContents/fetch', {
        storeUUID: this.authStore.uuid,
        params,
      })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    const route = this.$route;
    const prevRoute = this.$routeReferrer;
    const cannotFetch = (
      route.name === 'ai_assistant_history'
      && (
        !prevRoute
        || !prevRoute.name.includes('ai_assistant')
      )
    );

    if (cannotFetch) {
      return;
    }

    this.getContents(this.localType);
  },
};
</script>

<template>
  <div>
    <form
      class="is-flex is-justify-content-flex-end mb-5"
      @submit.prevent
    >
      <div class="field">
        <b-select
          v-model="localType"
          @input="getContents(localType)"
        >
          <option :value="null">
            Tous les contenus
          </option>
          <option
            v-for="one in $constants.AI_CONTENT_TYPES"
            :key="one"
            :value="one"
            v-t="`ai_assistant.content_types.${one}`"
          />
        </b-select>
      </div>
    </form>

    <article
      v-for="content in (contents && contents.data)"
      :key="content.uuid"
      class="box flex-between-center"
    >
      <header>
        <p>
          <span
            class="tag is-outlined mr-2"
            v-t="`ai_assistant.content_types.${content.type}`"
          />

          le <time>{{ content.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}</time>
        </p>
        <h3 class="title is-6 mt-3">
          {{ content.options.title || content.options.what }}
        </h3>
      </header>
      <p class="ml-5">
        <b-button @click="showContent(content)">
          Voir le contenu
        </b-button>
      </p>
    </article>

    <div v-if="!isLoading && contents.data.length <= 0" class="box">
      Pas de contenus générés.
    </div>

    <b-pagination
      v-if="contents && contents.meta.last_page > 1"
      :total="contents.meta.total"
      :per-page="contents.meta.per_page"
      aria-next-label="Page suivante"
      aria-previous-label="Page précédente"
      aria-page-label="Page"
      aria-current-label="Page actuelle"
      v-model="page"
      @change="getContents(localType, $event)"
    />

    <b-loading v-if="isLoading" active />
  </div>
</template>
